<template>
  <v-container>
    <v-layout align-center justify-center row fill-height>
      <v-flex md6>
        <v-card>
          <v-alert :value="error" type="error">{{error}}</v-alert>

          <v-card-text>
            <v-container justify-center row>
              <v-layout>
                <v-flex md12>
                  <h1>Anmeldung</h1>
                  <v-text-field v-model="login.user" name="username" label="Benutzername"></v-text-field>
                  <v-text-field
                    v-model="login.password"
                    label="Passwort"
                    name="password"
                    type="password"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn flat large color="primary" @click="fireLogin">Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import AuthService from "@/services/AuthService";
import router from "../router";
import EventBus from '@/event-bus'

export default {
  runtimeCompiler: true,
  components: {},
  data: () => ({
    login: {
      user: null,
      password: null
    },
    error: null
  }),
  methods: {
    async fireLogin() {
      try {
        await AuthService.login(this.login);
        EventBus.$emit('user-logged-in');
        router.push("/");
      } catch (ex) {
        this.error = ex.response.data[2].message;

        setTimeout(() => {
          this.error = null;
        }, 2000);
      }
    }
  },
  created() {}
};
</script>

<style>
</style>