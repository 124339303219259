<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import AuthService from '@/services/AuthService'
import router from './router'
import EventBus from '@/event-bus'

const IDLE_TIMEOUT = 30 * 60

export default {
  name: "app",
  
  data() {
    return {
      idleTimeout: null,
      countdownInterval: null,
      events: ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'],
      remainingTime: IDLE_TIMEOUT,
      isAuthenticated: false,
      user: null
    }
  },

  async created() {
    try {
      // Initial Auth-Status über User-Daten setzen
      const response = await AuthService.getUser();
      this.user = response.data;
      this.isAuthenticated = !!this.user;

      // Event-Listener für Login/Logout
      EventBus.$on('user-logged-in', this.handleLogin);
      EventBus.$on('user-logged-out', this.handleLogout);

      // Wenn bereits eingeloggt, Timer starten
      if (this.isAuthenticated) {
        console.log('Benutzer ist authentifiziert, starte Timer...');
        await this.setupIdleTimer();
      }

      this.setupMidnightLogout();
    } catch (error) {
      console.error('Fehler beim Initialisieren:', error);
      this.isAuthenticated = false;
      this.user = null;
    }
  },

  beforeDestroy() {
    this.cleanupIdleTimer();
    EventBus.$off('user-logged-in', this.handleLogin);
    EventBus.$off('user-logged-out', this.handleLogout);
  },

  methods: {
    async handleLogin() {
      try {
        console.log('Login erkannt, starte Timer...');
        const response = await AuthService.getUser();
        this.user = response.data;
        this.isAuthenticated = !!this.user;
        await this.setupIdleTimer();
      } catch (error) {
        console.error('Fehler beim Login:', error);
        this.isAuthenticated = false;
        this.user = null;
      }
    },

    handleLogout() {
      console.log('Logout erkannt, stoppe Timer...');
      this.isAuthenticated = false;
      this.user = null;
      this.cleanupIdleTimer();
    },

    async setupIdleTimer() {
      console.log('Setup Idle Timer...');
      // Cleanup bestehender Timer
      this.cleanupIdleTimer();

      // Timer initial starten
      this.remainingTime = IDLE_TIMEOUT;
      this.startCountdown();
      this.emitRemainingTime(); // Emit initial time

      // Event Listener für Benutzeraktivität registrieren
      this.events.forEach(event => {
        window.addEventListener(event, this.handleUserActivity);
      });
    },

    handleUserActivity() {
      if (!this.isAuthenticated) return;
      
      console.log('Benutzeraktivität erkannt, setze Timer zurück...');
      this.remainingTime = IDLE_TIMEOUT;
      this.emitRemainingTime();
    },

    startCountdown() {
      console.log('Starte Countdown...');
      // Bestehenden Countdown löschen
      if (this.countdownInterval) {
        clearInterval(this.countdownInterval);
      }

      // Neuen Countdown starten
      this.countdownInterval = setInterval(() => {
        if (this.remainingTime > 0) {
          this.remainingTime--;
          this.emitRemainingTime();
        }

        // Wenn Zeit abgelaufen ist und noch kein Logout durchgeführt wurde
        if (this.remainingTime === 0 && this.isAuthenticated) {
          console.log('Timer abgelaufen, führe Logout durch...');
          this.performLogout();
        }
      }, 1000);
    },

    emitRemainingTime() {
      const minutes = Math.floor(this.remainingTime / 60);
      const seconds = this.remainingTime % 60;
      EventBus.$emit('idle-timer-update', { minutes, seconds });
    },

    cleanupIdleTimer() {
      console.log('Cleanup Timer...');
      // Event Listener entfernen
      this.events.forEach(event => {
        window.removeEventListener(event, this.handleUserActivity);
      });

      // Timer löschen
      if (this.idleTimeout) {
        clearTimeout(this.idleTimeout);
        this.idleTimeout = null;
      }

      // Countdown löschen
      if (this.countdownInterval) {
        clearInterval(this.countdownInterval);
        this.countdownInterval = null;
      }
    },

    async performLogout() {
      try {
        await AuthService.logout();
        EventBus.$emit('user-logged-out');
        router.push('/auth/login');
      } catch (error) {
        console.error('Fehler beim Logout:', error);
      }
    },

    setupMidnightLogout() {
      const checkMidnight = () => {
        const now = new Date();
        if (now.getHours() === 0 && now.getMinutes() === 0) {
          if (this.isAuthenticated) {
            this.performLogout();
          }
        }
      };

      setInterval(checkMidnight, 30000);
    }
  },

  watch: {
    // Überwache den Router für Änderungen und den Auth-Status
    '$route': {
      immediate: true,
      async handler(to) {
        if (to.path !== '/auth/login') {
          try {
            const response = await AuthService.getUser();
            this.user = response.data;
            this.isAuthenticated = !!this.user;
            
            if (this.isAuthenticated && !this.countdownInterval) {
              console.log('Route geändert, prüfe Timer...');
              await this.setupIdleTimer();
            }
          } catch (error) {
            console.error('Fehler beim Abrufen der User-Daten:', error);
            this.isAuthenticated = false;
            this.user = null;
          }
        }
      }
    }
  }
};
</script>

<style>
@media (min-width: 960px) and (max-width: 961px) {
  h1 {
    font-size: 1em;
    line-height: 15px;
  }
  h2,
  h3 {
    font-size: 15px;
    line-height: 15px;
  }

  p {
    font-size: 12px;
    margin-block-start: 0px !important;
    margin-block-end: 0px !important;
    margin-inline-end: 0px !important;
    margin-inline-start: 0px !important;
    line-height: 18px !important;
  }

  .pa-3.v-card.v-sheet.theme--light > p {
    font-size: 1.4em !important;
  }

  .pa-3.v-card.v-sheet.theme--light > p > img {
    max-width: 170px !important;
  }
  .v-progress-linear.v-progress-linear--striped.theme--light {
    height: 10px !important;
    font-size: 10px !important;
  }
  .v-card__title {
    padding: 5px !important;
  }
  .v-card__text {
    padding: 5px !important;
  }
  .container {
    width: 100% !important;
  }
  button.v-btn.v-btn--text.theme--light.v-size--default.success--text {
    max-width: 48px !important;
    font-size: 10px !important;
  }
  button.v-btn.v-btn--text.theme--light.v-size--default.error--text {
    max-width: 48px;
    font-size: 10px;
  }

  span.v-btn__content {
    font-size: 12px !important;
    margin-right: 12px;
  }
}
</style>