<template>
  <div>
    <Header />
    <v-layout style="overflow-x: scroll">

      <v-flex v-for="(item, index) in filteredItems" :key="item?.id || index">

        <v-card tile @click="handleItemClick(item)" :class="currentItem.orderId == item.orderId ? 'selectedTile' : ''" style="height: 40px; min-width: 50px" :color="item?.status == 'packed'
          ? currentItem.orderId == item.orderId
            ? 'orange'
            : 'success'
          : currentItem.orderId == item.orderId
            ? 'grey'
            : 'white'
          "></v-card>
      </v-flex>
      <v-flex v-if="items.length > filteredItems.length">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-card tile style="height: 40px; min-width: 50px; opacity: 0.5" color="grey" v-bind="attrs" v-on="on">...</v-card>
          </template>
          <span>{{ items.length - filteredItems.length }} weitere gepackte Pakete</span>
        </v-tooltip>
      </v-flex>
    </v-layout>
    <v-container mb-10 style="max-width: 98%;">

      <v-row>

        <v-col cols="8" style="font-size: 0.8em; padding:0px">
          <div class="order-info">
            <span class="info-item">Paket {{ this.index + 1 }} von {{ this.items.length }}</span>
            <span class="info-divider">|</span>
            <span class="info-item">Bestellnummer: {{ this.currentItem.orderId }}</span>
            <span class="info-divider">|</span>
            <span class="info-item">Bestellt am {{ this.currentItem.createDate | moment("DD.MM.yyyy HH:mm") }}</span>
            <span class="info-divider">|</span>
            <span class="info-item">Marktplatz: {{ this.currentItem.shop }}</span>
          </div>

          <v-alert :color="progress > 0 ? 'info' : 'error'" :value="true" dense class="time-alert">
            <v-progress-linear :value="progress" height="25" rounded :color="barColor" :class="{ 'blink': isBlinking }">
              <template v-slot:default>
                <div class="time-info">
                  <span>Packzeit: <strong>{{ minutes }}m {{ seconds }}s</strong></span>
                  <span class="time-divider">|</span>
                  <span>⌀ Heute: <strong>{{ Math.round(packStats.time) }}s</strong></span>
                  <span>({{ packStats.count }} Pakete)</span>
                </div>
              </template>
            </v-progress-linear>
          </v-alert>
        </v-col>
        <v-col cols="4" style="text-align: right">

          <h3 style="color: green; display: inline-block">
            Noch
            {{ totalOpenItems }} Pakete
          </h3>

          <v-icon style="margin-left: 20px" primary @click="showPrinterSelectionDialog = true">settings</v-icon>
        </v-col>
      </v-row>
      <!-- Top bar  -->

      <v-row v-if="items[index]?.status == 'packed'">
        <v-col cols="12">
          <v-alert type="success">Bereits gepackt</v-alert>
        </v-col>

      </v-row>
      <v-row v-if="error">
        <v-col cols="12">
          <v-alert :value="error" type="error">{{ error }}</v-alert>
        </v-col>
      </v-row>
      <v-row v-if="currentItem && currentItem.warranty == true">
        <v-col cols="12">
          <v-alert type="warning">Das ist eine Reklamation. <span v-if="currentItem.warrantyReason">Grund: {{ currentItem.warrantyReason }}</span> <span v-if="currentItem.warrantyNote">Notiz: {{ currentItem.warrantyNote }}</span></v-alert>
        </v-col>

      </v-row>
      <v-row v-if="currentItem && currentItem.orderId">
        <v-col cols="8" class="mb-15">
          <v-card class="pa-3" :class="{
            'multiCart': currentItem?.products?.filter(x => x?.sku)?.length > 1,
            'multiQty': currentItem?.products?.filter(x => x?.sku)?.length === 1 && currentItem?.products[0]?.qty > 1
          }">
            <h2 v-if="currentItem?.products?.filter(x => x?.sku)?.length > 1">
              DIESE BESTELLUNG HAT MEHRERE ARTIKEL
              <h1 class="d-inline-block black white--text pa-1" style="font-size: 1.2em">
                {{ currentItem?.products?.filter(x => x?.sku)?.length }} ARTIKEL
              </h1>
            </h2>

            <div v-for="(p, idx) in currentItem?.products?.filter(x => x?.sku) || []" :key="p.sku + '_' + idx" class="border pa-2">
              <div v-if="p?.status == 'ready'" class="ready">
                Bereit <span v-if="p.shelf">in Fach {{ p.shelf.slot }}</span>
              </div>
              <div v-if="p?.status == 'missing'" class="missing">Fehlt</div>

              <span class="blockLine text-h6">SKU: <span class="customValue">{{ p.sku }}</span></span>

              <h2 v-if="p.sku == 'zippo_gb_foto'" class="black magenta--text">ZIPPO GESCHENKSET!</h2>

              <template v-for="(a, attrIdx) in filterAttributes(p.attributes)" :key="p.sku + '_' + a + '_' + attrIdx">
                <p class="mb-0">
                  <v-img v-if="p.attributes[a] != null && a == 'designId'" :src="'https://accelery-poster.s3.eu-central-1.amazonaws.com/' + encodeURIComponent(p.attributes[a]) + '.png'" class="grey" max-height="300" contain :style="p.sku === 'LYTO_MESSLATTE_70' ? 'transform: rotate(90deg)' : ''" />
                </p>
                <p class="mb-0">
                  <v-img v-if="p.attributes['designId'] == null && p.attributes[a] != null && p.attributes[a].toString().includes('http')" :src="p.attributes[a]" class="grey" max-height="300" contain />
                  <span v-else class="blockLine">
                    {{ a }}: <span class="customValue">{{ p.attributes[a] }}</span>
                  </span>
                </p>
              </template>

              <p v-if="p.translated?.line1 && p.translated?.font1" class="mb-0">
                <span class="blockLine">
                  <span :class="'font-' + p.translated.font1">{{ p.translated.line1 }}</span>
                </span>
              </p>

              <span v-if="p.qty > 1" class="quantity-badge">{{ p.qty }}x</span>
              <span class="blockLine qty" :class="{ 'highlight-qty': p.qty > 1 }">
                Menge: <span class="customValue">{{ p.qty }}</span>
              </span>
            </div>
          </v-card>

          <div class="text-center">
            <v-btn class="ma-2" @click="openDamagedDialogResetOnly">Produkt zurücksetzen</v-btn>
          </div>
        </v-col>

        <v-col cols="4" class="mb-15">
          <v-card class="pa-3 grey lighten-3" height="100%">
            <div v-if="currentItem.shipping?.type == 'PRIME'">
              <h1> <img src="@/assets/prime.png" height="30px"  /> Prime</h1>
            </div>
            <h1>Adresse</h1>
            <p class="text-subtitle-1 line-height-1-4">
              <span class="blockLine name">
                {{ currentItem.customer.firstName }} {{ currentItem.customer.lastName }}
              </span>
              <span class="blockLine">{{ currentItem.customer.adress }}</span>
              <span class="blockLine">
                {{ currentItem.customer.zip }} {{ currentItem.customer.city }}
              </span>
              <span class="blockLine">{{ currentItem.customer.country }}</span>
            </p>

            <v-alert type="warning" v-if="currentItem.shop == 'amazon'">
              Geschenkfreude Flyer beilegen
            </v-alert>
            <v-alert type="success" v-if="currentItem.shop == 'muckelmeister'">
              MuckelMeister Flyer beilegen
            </v-alert>
            <v-alert type="success" v-if="currentItem.shop == 'acs' && currentItem.reference.includes('#FW')">
              Famwalls Flyer beilegen
            </v-alert>
            <v-alert type="success" v-if="currentItem.shop == 'acs' && currentItem.reference.includes('#BP')">
              Bo & Birdie Flyer beilegen
            </v-alert>
            <v-alert type="error" v-if="currentItem.registered == undefined">
              Versand noch nicht angemeldet!
            </v-alert>

            <v-btn @click="showFeedbackDialog = true">Problem melden</v-btn>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else>

        <v-col cols="12" class="text-center">
          <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
          <p class="mt-4">Lade Daten...</p>
        </v-col>

      </v-row>
      <!--  Content -->

    </v-container>


    <div class="bottomControls">
      <v-container>
        <v-row v-if="currentItem.registered == undefined" style="background: red">
          <v-col cols="6">
            <v-btn @click="under5cm = true" small block :class="under5cm ? 'btnSelected' : ''">Unter 5 cm</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn @click="under5cm = false" small block :class="!under5cm ? 'btnSelected' : ''">Über 5 cm</v-btn>
          </v-col>
        </v-row>
        <v-row class="text-md-center" v-if="!missingItems">

          <v-col pa-2 v-if="workflow.hasShelfItems">
            <v-btn block color="warning" @click="startMultiCartAction();" large :loading="isProcessingMultiCart">
              In Warenkorbregal legen
            </v-btn>
          </v-col>

          <v-col v-if="workflow.canPrint" pa-2>
            <v-btn block color="info" @click="print()" large :loading="printing" :disabled="cooldown > 0">
              Drucken
              <v-icon>print</v-icon>
              <span style="padding-left: 8px" v-if="cooldown > 0">({{ cooldown }})</span>
            </v-btn>
          </v-col>


          <v-col v-if="workflow.canPack" pa-2>
            <v-btn block color="success" @click="pack()" large :disabled="cooldown > 0">
              Gepackt
              <v-icon>check</v-icon>
              <span style="padding-left: 8px" v-if="cooldown > 0">({{ cooldown }})</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="text-md-center" v-else-if="missingItems">
          <v-col pa-2>
            <v-btn block color="warning" @click="startMultiCartAction();" large :loading="printing">
              In Warenkorbregal legen
            </v-btn>
          </v-col>

        </v-row>
      </v-container>
    </div>

    <v-dialog v-model="showPrinterSelectionDialog" scrollable max-width="400px">
      <v-card>
        <v-card-title>Wähle Deinen Drucker aus</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px">

          <v-btn @click="loadMultiCartOrders()">Nur MultiCart anzeigen</v-btn>
          <v-radio-group v-model="printer" column>
            <v-radio v-for="p in printers" :key="p.url" :label="p.name" :value="p.url"></v-radio>
          </v-radio-group>

          <!--<v-switch v-model="autoPrint" inset label="Automatischer Etikettendruck"></v-switch>-->
          <v-btn color="red" v-if="isAdmin()" @click="resetPacklist()">Packliste zurücksetzen</v-btn>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="showPrinterSelectionDialog = false">
            Abbrechen
          </v-btn>
          <v-btn color="blue darken-1" text @click="savePrinter(); showPrinterSelectionDialog = false;">
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="multiCartSlotDialog" scrollable max-width="800px">
      <v-card v-if="multiCartPayload">
        <v-card-title>Lege folgende Artikel in das Warenkorbregal</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <!-- large number -->
              <v-card class="pa-3" color="#f0f0f0" height="100%">
                <h1>Fach</h1>
                <p style="font-size: 4.1em !important;line-height: 1.4em !important;">
                  <span class="blockLine">{{ multiCartPayload.location }}</span>
                </p>
              </v-card>
            </v-col>
            <v-col cols="8">
              <v-card class="pa-3" color="#f0f0f0" height="100%">
                <h1>Artikel</h1>
                <div v-if="multiCartPayload && multiCartPayload.items" style="border: 1px solid #000; padding: 5px;" v-for="(p, idx) in currentItem.products.filter(x => x?.status === 'ready' && !x?.shelf)" :key="p.sku + '_' + idx">
                  <v-checkbox v-model="multiCartPayload.items" :value="p" :label="'SKU: ' + p.sku" :input-value="p.translated?.table === $route.params.id"></v-checkbox>
                  <p v-for="(a, attrIdx) in filterAttributes(p.attributes)" :key="p.sku + '_' + a + '_' + attrIdx" style="margin-bottom:0px !important">
                    <v-img v-if="p.attributes[a] != null && p.attributes[a].toString().indexOf('http') > -1" :src="p.attributes[a]" height="200px" style="background-color: grey" contain></v-img>
                    <span v-else class="blockLine">{{ a }}: <span class="customValue">{{ p.attributes[a] }}</span></span>
                  </p>
                  <span class="blockLine qty">Menge: <span class="customValue">{{ p.qty }}</span></span>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="multiCartSlotDialog = false">
            Abbrechen
          </v-btn>
          <v-btn color="blue darken-1" text @click="putMultiCartItems(); multiCartSlotDialog = false;">
            Erledigt
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="manualMultiCartAction.dialog" scrollable max-width="800px">
      <v-card v-if="manualMultiCartAction.dialog">
        <v-card-title>Welche Artikel möchtest du in den Warenkorb legen?</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <!-- large number -->
              <v-card class="pa-3" color="#f0f0f0" height="100%">
                <h1>Fach</h1>
                <p style="font-size: 4.1em !important;line-height: 1.4em !important;">
                  <v-select v-model="manualMultiCartAction.location" :items="manualMultiCartAction.options" label="Fach"></v-select>
                </p>
              </v-card>
            </v-col>
            <v-col cols="8">
              <v-card class="pa-3" color="#f0f0f0" height="100%">
                <h1>Artikel</h1>
                <div v-if="currentItem.products" style="border: 1px solid #000; padding: 5px;" v-for="(p, idx) in currentItem.products.filter(x => x?.status == 'ready' && !x.shelf)" :key="p.sku + '_' + idx">
                  <v-checkbox v-model="manualMultiCartAction.items" :value="p" :label="'SKU: ' + p.sku"></v-checkbox>
                  <p v-for="(a, attrIdx) in filterAttributes(p.attributes)" :key="p.sku + '_' + a + '_' + attrIdx" style="margin-bottom:0px !important">
                    <v-img v-if="p.attributes[a] != null && p.attributes[a].toString().indexOf('http') > -1" :src="p.attributes[a]" height="200px" style="background-color: grey" contain></v-img>
                    <span v-else class="blockLine">{{ a }}: <span class="customValue">{{ p.attributes[a] }}</span></span>
                  </p>
                  <span class="blockLine qty">Menge: <span class="customValue">{{ p.qty }}</span></span>
                  <hr>
                </div>
              </v-card>
            </v-col>
          </v-row>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="manualMultiCartAction.dialog = false">
            Abbrechen
          </v-btn>
          <v-btn color="blue darken-1" text @click="startManualMultiCartAction()">
            Erledigt
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="showFeedbackDialog" scrollable max-width="800px">
      <v-card :loading="loading">
        <v-card-title>Problem melden</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-textarea v-model="feedback" label="Was ist das Problem?" rows="3"></v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="showFeedbackDialog = false">
            Abbrechen
          </v-btn>
          <v-btn color="blue darken-1" text @click="sendFeedback();">
            Senden
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>




    <DamagedDialog />

    <!-- Reklamations-Dialog -->
    <v-dialog v-model="showWarrantyDialog" persistent max-width="500">
      <v-card>
        <v-card-title class="headline warning">
          Achtung - Reklamation!
        </v-card-title>
        <v-card-text class="pt-4">
          <div v-if="currentItem && currentItem.warranty">
            <p class="subtitle-1 font-weight-bold">Dies ist eine Reklamation!</p>
            <v-divider class="my-3"></v-divider>
            <div v-if="currentItem.warrantyReason" class="my-2">
              <span class="font-weight-medium">Grund:</span>
              <p class="mt-1">{{ currentItem.warrantyReason }}</p>
            </div>
            <div v-if="currentItem.warrantyNote" class="my-2">
              <span class="font-weight-medium">Notiz:</span>
              <p class="mt-1">{{ currentItem.warrantyNote }}</p>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="warning"
            text
            @click="acknowledgeWarranty"
          >
            Verstanden
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue';
import Header from "../header";
import packlistService from "@/services/PacklistService";
import AuthService from "@/services/AuthService";
import router from "@/router";

import DamagedDialog from "./DamagedDialog.vue";

import EventBus from "@/event-bus";

const COOL_DOWN_TIME = 2;

export default {
  name: "production",
  components: {
    Header,
    DamagedDialog,
  },
  data: () => ({
    loading: true,
    percent: 0,
    items: [],
    index: 0,
    sheet: false,
    currentItem: {
      sku: null,
      finished: false,
      products: [],
      customer: {},
      orderId: null,
      shop: null,
      createDate: null,
      warranty: false,
      warrantyReason: null,
      warrantyNote: null,
      reference: null,
      registered: null
    },
    show: false,
    packedAmount: 0,
    error: null,
    catImg: null,
    lists: null,
    showPrinterSelectionDialog: false,
    printers: null,
    printer: null,
    printing: false,
    interval: null,
    autoPrint: false,
    init: false,
    under5cm: true,
    keyPressed: [],
    cooldownInterval: null,
    seconds: 0,
    minutes: 0,
    totalTime: 26, // 25 seconds
    remainingTime: 26,
    progress: 100,
    isBlinking: false,
    operatorState: 1,
    startTime: new Date(),
    missingItems: false,
    multiCartSlot: null,
    multiCartSlotDialog: false,
    multiCartOrders: [],
    multiCartPayload: null,
    packStats: { time: "", count: 0 },
    user: null,
    cooldown: 0,
    packStatsInterval: null,

    manualMultiCartAction: {
      options: ["A1", "A2", "A3", "A4", "A5", "A6", "A7", "A8", "B1", "B2", "B3", "B4", "B5", "B6", "B7", "B8", "C1", "C2", "C3", "C4", "C5", "C6", "C7", "C8", "D1", "D2", "D3", "D4", "D5", "D6", "D7", "D8", "E1", "E2", "E3", "E4", "E5", "E6", "E7", "E8"],
      dialog: false,
      location: null,
      items: []
    },
    showFeedbackDialog: false,
    feedback: "",
    workflow: {
      canPack: false,
      canPrint: true,
      hasShelfItems: false
    },
    isProcessingMultiCart: false,
    isPrinting: false,
    tableId: null,
    showWarrantyDialog: false,
  }),
  created() {
    this.tableId = this.$route.params.id;
    if (!this.tableId) {
      this.error = 'Tisch-ID fehlt';
      return;
    }
  },
  computed: {
    barColor() {
      if (this.progress > 50) return 'green';
      if (this.progress > 25) return 'orange';
      return 'red';
    },

    filteredItems() {
      if (!this.items || !Array.isArray(this.items)) return [];

      // Hole die ersten 100 offenen Items
      const openItems = this.items
        .filter(x => x && x.status === "open")
        .slice(0, 100);

      // Hole die letzten 20 gepackten Items, sortiert nach Packzeitpunkt
      const packedItems = this.items
        .filter(x => x && x.status === "packed")
        .sort((a, b) => {
          // Sortiere nach Packzeitpunkt, neueste zuerst
          const dateA = new Date(a?.packEnd || 0);
          const dateB = new Date(b?.packEnd || 0);
          return dateB - dateA;
        })
        .slice(0, 20);

      // Kombiniere offene und gepackte Items
      return [...openItems, ...packedItems];
    },

    totalOpenItems() {
      return this.items?.filter(x => x?.status === "open")?.length || 0;
    }
  },

  async mounted() {
    try {
      await this.loadPrinters();
      this.loading = false;
      await this.refresh();
      await this.loadPackStats();

      this.user = (await AuthService.getUser()).data;

      this.interval = setInterval(() => this.refresh(), 5000);
      this.cooldownInterval = setInterval(() => {
        if (this.cooldown > 0) {
          this.cooldown--;
        }
      }, 1000);
      this.packStatsInterval = setInterval(() => this.loadPackStats(), 10000);

      //calculate time
      this.timerInterval = setInterval(() => {
        let now = new Date();
        let diff = now - this.startTime;
        this.seconds = Math.floor(diff / 1000);
        this.minutes = Math.floor(this.seconds / 60);
        this.seconds = this.seconds % 60;

        this.remainingTime -= 0.5;
        this.progress = (this.remainingTime / this.totalTime) * 100;

        if (this.remainingTime <= 0) {
          this.startBlinking();
        }
      }, 500);

      EventBus.$on("REFRESH_PACKLIST", this.handleRefreshPacklist);

      this.boundHandleKeyPress = this.handleKeyPress.bind(this);
      window.addEventListener("keyup", this.boundHandleKeyPress);
    } catch (error) {
      console.error('Fehler beim Mounting:', error);
      this.error = 'Fehler beim Initialisieren: ' + error.message;
    }
  },

  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.cooldownInterval);
    clearInterval(this.timerInterval);
    clearInterval(this.packStatsInterval);
    window.removeEventListener("keyup", this.boundHandleKeyPress);
    EventBus.$off("REFRESH_PACKLIST", this.handleRefreshPacklist);
  },

  watch: {
    index: {
      handler: async function (newVal, oldVal) {
        // Verhindere Endlosschleife und ungültige Indizes
        if (newVal === oldVal || !this.items || newVal < 0 || newVal >= this.items.length) {
          return;
        }

        try {
          const orderId = this.items[newVal]?.orderId;
          console.log("orderId", orderId);
          if (orderId) {
            console.log("loadOrder", orderId);
            await this.loadOrder(orderId);
          }
        } catch (error) {
          console.error('Fehler im Index-Watcher:', error);
        }
      },
      immediate: true // Wird beim Start ausgeführt
    },
    'currentItem': {
      immediate: true,
      handler(newItem) {
        if (newItem && newItem.warranty === true) {
          this.showWarrantyDialog = true;
        }
      }
    }
  },

  methods: {

    isAdmin() {
      if (this.user && this.user.role == "admin") {
        return true;
      }
      return false;
    },

    async resetPacklist() {
      await packlistService.resetPacklist(this.$route.params.id);
      await this.refresh();
    },

    async loadMultiCartOrders() {
      try {
        const response = await packlistService.getMultiCartOrders();

        let hasMultiCart = this.multiCartOrders.length === 0;
        this.multiCartOrders = response.data;

        if (this.multiCartOrders.length === 0) {
          this.error = "Keine offenen MultiCart Bestellungen gefunden";
          return;
        }

        // Filtere Items basierend auf MultiCart Orders
        this.items = this.items.filter(x =>
          this.multiCartOrders.some(y => y.orderId === x.orderId)
        );

        if (this.items.length === 0) {
          this.error = "Keine offenen MultiCart Bestellungen gefunden";
          this.multiCartOrders = [];
          return; // Kein refresh() hier
        }

        if (hasMultiCart) {
          this.index = 0;
        }
      } catch (error) {
        console.error('Fehler beim Laden der MultiCart Orders:', error);
        this.error = error.message;
      }
    },

    async refresh(reloadOrder = false) {
      this.loading = true;

      if (this.multiCartOrders.length > 0) {
        await this.loadMultiCartOrders();
        return;
      }

      try {
        const response = await packlistService.getPacklistItems(this.$route.params.id);
        if (!response?.data) {
          throw new Error('Keine Daten empfangen');
        }

        this.items = response.data;

        if (!Array.isArray(this.items) || this.items.length === 0) {
          throw new Error('Keine Items verfügbar');
        }

        if (!this.init) {
          console.log("init");
          // Explizite Index-Initialisierung beim ersten Laden
          const openItemIndex = this.items.findIndex(i => i && i.status === "open");
          console.log("openItemIndex", openItemIndex);
          this.index = openItemIndex >= 0 ? openItemIndex : 0;
          console.log("index", this.index);
          this.init = true;
          console.log("init done");
          if (this.index == 0) {
            //load order
            await this.loadOrder(this.items[this.index].orderId);
          }
        } else {
          // Stelle sicher dass der aktuelle Index noch gültig ist
          if (this.index >= this.items.length) {
            this.index = Math.max(0, this.items.length - 1);
          }
        }

      } catch (err) {
        console.error('Fehler beim Refresh:', err);
        this.error = err.message || 'Fehler beim Laden der Packliste';
        if (err.response?.status === 401) {
          router.replace("/auth/login");
        }
      } finally {
        this.loading = false;
      }
    },

    async openDamagedDialog() {
      EventBus.$emit("OPEN_DAMAGED_DIALOG", this.currentItem);
    },

    async openDamagedDialogResetOnly() {
      EventBus.$emit("OPEN_DAMAGED_DIALOG_RESETONLY", this.currentItem);
    },

    async loadPrinters() {
      this.printers = (await packlistService.getPrinters()).data;
    },

    startBlinking() {
      this.isBlinking = !this.isBlinking;
    },

    async loadPackStats() {
      try {
        const response = await packlistService.getPackStats();
        if (response?.data) {
          this.packStats = response.data;
        }
      } catch (error) {
        console.error('Fehler beim Laden der PackStats:', error);
      }
    },

    async loadOrder(orderId) {
      if (!orderId) return;

      try {
        this.loading = true;
        this.error = null;

        console.log("CurrentItem");
        console.log(this.currentItem);

        this.currentItem.orderId = null;

        //wait for next tick
        await new Promise(resolve => setTimeout(resolve, 0));

        console.log("loadOrder", orderId);
        this.currentItem = (await packlistService.getPacklistItem(orderId)).data;
        console.log("CurrentItem After");
        console.log(this.currentItem);
        // Sicherstellen, dass products ein Array ist
        if (!this.currentItem.products) {
          this.currentItem.products = [];
        }

        let ignoreSkus = [
          "shopify_extra_helle_gravur",
          "Artikel_Extras",
        ];

        // Sicherstellen dass alle benötigten Eigenschaften existieren
        /*this.currentItem.products = this.currentItem.products
          .filter(x => x && x.sku && ignoreSkus.indexOf(x.sku) === -1)
          .map(product => ({
            ...product,
            attributes: product?.attributes || {},
            status: product?.status || 'unknown',
            shelf: product?.shelf || null
          }));*/

        this.workflow.hasShelfItems = this.currentItem.products.some(x => x?.shelf) ||
          this.currentItem.products.length > 1;

        this.missingItems = this.currentItem.products.filter(
          x => x?.status === "missing"
        ).length > 0;

        // Sicherstellen dass this.items[this.index] existiert
        if (this.items[this.index]?.id) {
          await packlistService.trackStart(this.items[this.index].id);
        }

        this.startTime = new Date();
        this.remainingTime = this.totalTime;
        this.progress = 100;
        this.isBlinking = false;

        if (this.autoPrint) this.print();

        let selectedTile = document.querySelector(".selectedTile");
        if (selectedTile) {
          selectedTile.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }

      } catch (error) {
        console.error('Fehler beim Laden des Orders:', error);
        this.error = 'Fehler beim Laden der Bestellung';
      } finally {
        this.loading = false;
      }
    },

    async print() {
      if (this.cooldown > 0 || this.printing || !this.workflow.canPrint) return;

      try {
        this.printing = true;
        const printerCookie = this.$cookies.get("printer");

        if (!printerCookie) {
          await this.loadPrinters();
          this.showPrinterSelectionDialog = true;
          return;
        }

        if (!this.currentItem?.orderId) {
          throw new Error('Keine gültige Bestellung zum Drucken');
        }

        await packlistService.print(
          this.currentItem.orderId,
          printerCookie,
          this.under5cm
        );

        this.cooldown = COOL_DOWN_TIME;
        this.workflow.canPrint = false;
        this.workflow.canPack = true;
        console.log("print done");

      } catch (error) {
        console.error('Druckfehler:', error);
        this.error = 'Fehler beim Drucken des Labels';
        this.workflow.canPrint = true;
        this.workflow.canPack = false;
      } finally {
        this.printing = false;
      }
    },

    async startMultiCartAction() {
      if (this.isProcessingMultiCart || !this.currentItem?.orderId) return;

      const printerCookie = this.$cookies.get("printer");
      if (!printerCookie) {
        this.error = 'Bitte wählen Sie zuerst einen Drucker aus';
        this.showPrinterSelectionDialog = true;
        return;
      }

      this.isProcessingMultiCart = true;
      try {
        this.multiCartSlot = (await packlistService.requestMultiCartSlot(
          this.currentItem.orderId,
          { printer: printerCookie }
        )).data;

        if (!this.multiCartSlot?.location) {
          throw new Error('Ungültige Slot-Zuweisung');
        }

        // Vorauswahl der Artikel basierend auf table-Match
        const preselectedItems = this.currentItem.products
          .filter(x => x?.status === "ready" && !x?.shelf && this.items.some(y => y.sku === x.sku));

        // Extrahiere die SKUs der ausgewählten Artikel
        const preselectedSkus = preselectedItems.map(item => item.sku);

        // Debugging
        console.log('Selected Items:', preselectedItems);
        console.log('Items:', this.items.filter(x => x.orderId === this.currentItem.orderId.toString()));
        console.log('PreselectedSkus:', preselectedSkus);

        // Sammle die packlistItemIds der betroffenen Artikel
        const packlistItemIds = this.items
          .filter(x =>
            x.orderId === this.currentItem.orderId.toString() && // orderId als String vergleichen
            preselectedSkus.includes(x.sku) &&
            x.status === 'open' // Nur offene Einträge berücksichtigen
          )
          .map(x => x.id);

        console.log('Found packlistItemIds:', packlistItemIds);

        this.multiCartPayload = {
          packlistItemIds: packlistItemIds,
          location: this.multiCartSlot.location,
          items: preselectedItems
        }

        this.multiCartSlotDialog = true;

      } catch (error) {
        console.error('Fehler beim MultiCart Start:', error);
        this.error = error.message || 'Fehler beim Starten der MultiCart Aktion';
      } finally {
        this.isProcessingMultiCart = false;
      }
    },

    async startManualMultiCartAction() {
      if (!this.manualMultiCartAction.location || !this.manualMultiCartAction.items.length) {
        this.error = 'Bitte Fach und mindestens einen Artikel auswählen';
        return;
      }

      try {
        this.multiCartPayload = {
          packlistItemId: this.currentItem.id,
          location: this.manualMultiCartAction.location,
          items: this.manualMultiCartAction.items,
          printer: this.$cookies.get("printer")
        }

        await this.putMultiCartItems();

        // Reset states
        this.manualMultiCartAction.dialog = false;
        this.multiCartPayload = {
          location: null,
          items: [],
          printer: null
        };
        this.manualMultiCartAction.items = [];
        this.manualMultiCartAction.location = null;

      } catch (error) {
        console.error('Fehler bei manueller MultiCart Aktion:', error);
        this.error = 'Fehler beim manuellen Einlagern';
      }
    },

    async putMultiCartItems() {
      if (!this.multiCartPayload || !this.multiCartPayload.items.length) {
        this.error = 'Keine Artikel zum Einlagern ausgewählt';
        return;
      }

      if (!this.multiCartPayload.packlistItemIds?.length) {
        this.error = 'Keine Packlisteneinträge gefunden';
        return;
      }

      this.isProcessingMultiCart = true;
      try {
        // Sende Request zum Server
        await packlistService.putMultiCartItems(
          this.currentItem.orderId,
          this.multiCartPayload
        );

        // Schließe Dialog
        this.multiCartSlotDialog = false;

        // Setze Cooldown
        this.cooldown = COOL_DOWN_TIME;

        // Lade die komplette Liste neu
        await this.refresh();


        const orderId = this.items[this.index]?.orderId;
        if (orderId) {
          await this.loadOrder(orderId);
        }

        /*// Finde den nächsten offenen Eintrag
        const nextOpenIndex = this.items.findIndex(
          (item, idx) => idx > this.index && item.status === 'open'
        );

        // Setze den Index
        if (nextOpenIndex >= 0) {
          this.index = nextOpenIndex;
        } else {
          const nextIndex = Math.min(this.index + 1, this.items.length - 1);
          this.index = nextIndex;
        }*/

      } catch (error) {
        console.error('Fehler beim Einlagern:', error);
        this.error = 'Fehler beim Einlagern der Artikel';
      } finally {
        this.isProcessingMultiCart = false;
      }
    },

    next() {
      if (this.index + 1 >= this.items.length) return;
      this.index++;
      this.cooldown = COOL_DOWN_TIME;
    },

    prev() {
      if (this.index - 1 < 0) return;
      this.index--;
      this.cooldown = COOL_DOWN_TIME;
    },

    savePrinter() {
      this.$cookies.set("printer", this.printer);
    },

    async pack() {
      if (this.cooldown > 0 || !this.workflow.canPack || !this.items) return;

      try {
        // Speichere aktuelle Position und Item
        const currentIndex = this.index;
        const currentItem = this.items[currentIndex];

        if (!currentItem?.id) {
          throw new Error('Ungültiges Item zum Packen');
        }

        // Sende pack-Request
        await packlistService.trackEnd(currentItem.id);

        // Aktualisiere den Status im lokalen Array
        /*if (this.items[currentIndex]) {
          this.$set(this.items[currentIndex], 'status', 'packed');
        }*/


        // Setze Timer zurück
        this.startTime = new Date();

        // Aktualisiere Workflow-Status
        this.workflow.canPack = false;
        this.workflow.canPrint = true;

        // Lade die komplette Liste neu
        await this.refresh();

        // Finde das nächste offene Item
        const nextOpenIndex = this.items.findIndex(
          (item, idx) => idx > currentIndex && item?.status === 'open'
        );

        // Setze den Index auf das nächste offene Item oder behalte den aktuellen
        if (nextOpenIndex >= 0) {
          this.index = nextOpenIndex;
        } else if (currentIndex + 1 < this.items.length) {
          this.index = currentIndex + 1;
        }

      } catch (error) {
        console.error('Fehler beim Packen:', error);
        this.error = 'Fehler beim Packen des Items';
        this.workflow.canPack = true;
        this.workflow.canPrint = false;
      }
    },

    filterAttributes(attributes) {
      try {


        const blackList = [
          "Schriftfarbe",
          "Schriffarbe",
          "Schriftfarbe2",
          "Schriftart2",
          "subtotal",
          "vQty",
        ];
        let attr = Object.keys(attributes).filter(
          (x) => blackList.indexOf(x.toString()) == -1
        );
        return attr;
      } catch (error) {
        console.error('Fehler beim Filtern der Attribute:', error);
        return [];
      }
    },

    toggleImageSize(event) {
      if (event.target.style.maxHeight == "300px") {
        event.target.style.maxHeight = "800px";
      } else {
        event.target.style.maxHeight = "300px";
      }
    },

    async sendFeedback() {
      let packlistName = this.$route.params.id;
      this.loading = true;
      await packlistService.sendFeedback(this.currentItem.orderId, packlistName + " | " + this.feedback);
      this.loading = false;
      this.feedback = "";
      this.showFeedbackDialog = false;
    },

    async handleKeyPress(e) {
      if (this.showFeedbackDialog) return;

      try {
        if (e.key === "a") {
          this.under5cm = !this.under5cm;
        }

        if (e.key === "b" && this.workflow.canPrint && !this.printing) {
          try {
            await this.$nextTick();
            await this.print();
          } catch (error) {
            console.error('Print error:', error);
            this.error = 'Fehler beim Drucken';
            this.workflow.canPrint = true;
            this.workflow.canPack = false;
          }
        }

        if (e.key === "c" && this.workflow.canPack) {
          try {
            await this.$nextTick();
            await this.pack();
          } catch (error) {
            console.error('Pack error:', error);
            this.error = 'Fehler beim Packen';
            this.workflow.canPack = true;
            this.workflow.canPrint = false;
          }
        }
      } catch (error) {
        console.error('Keypress error:', error);
        this.error = 'Fehler bei der Tastatursteuerung';
      }
    },

    handleRefreshPacklist(order) {
      this.init = false;
      this.index = 0;
      this.refresh();
    },

    async handleItemClick(item) {
      // Finde den korrekten Index im items Array
      const newIndex = this.items.findIndex(i => i.id === item.id);

      if (newIndex !== -1) {
        console.log('Setze Index auf:', newIndex, 'für Item:', item);
        this.index = newIndex;
      } else {
        console.error('Item nicht im items Array gefunden:', item);
      }

      // Wenn es eine Reklamation ist, zeige den Dialog
      if (item.warranty === true) {
        this.showWarrantyDialog = true;
      }
    },

    acknowledgeWarranty() {
      this.showWarrantyDialog = false;
    },
  },
};
</script>

<style scoped>
.qty {
  font-size: 1.4em !important;
}

.qty>.customValue {
  background: black;
  padding: 5px;
}

.customvalue,
.customValue {
  font-weight: bold !important;
  color: red !important;
}

.name {
  font-weight: bold;
  color: green;
}

.blockLine {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.multiCart {
  background-color: lime !important;
}

.btnSelected {
  border: 3px solid lime;
}

.btnSelected>span {
  background-color: lime !important;
}

.bottomControls {
  bottom: 0px;
  position: fixed;
  left: 0px;
  right: 0px;
}

@font-face {
  font-family: "Schrift_1";
  src: url(~@/assets/fonts/Schrift_1.woff) format("woff");
}

@font-face {
  font-family: "Schrift_2";
  src: url(~@/assets/fonts/Schrift_2.woff) format("woff");
}

@font-face {
  font-family: "Schrift_3";
  src: url(~@/assets/fonts/Schrift_3.woff) format("woff");
}

@font-face {
  font-family: "Schrift_4";
  src: url(~@/assets/fonts/Schrift_4.woff) format("woff");
}

@font-face {
  font-family: "Schrift_5";
  src: url(~@/assets/fonts/Schrift_5.woff) format("woff");
}

@font-face {
  font-family: "Schrift_6";
  src: url(~@/assets/fonts/Schrift_6.woff) format("woff");
}

@font-face {
  font-family: "Schrift_7";
  src: url(~@/assets/fonts/Schrift_7.woff) format("woff");
}

@font-face {
  font-family: "Schrift_8";
  src: url(~@/assets/fonts/Schrift_8.woff) format("woff");
}

@font-face {
  font-family: "Schrift_9";
  src: url(~@/assets/fonts/Schrift_9.woff) format("woff");
}

@font-face {
  font-family: "Schrift_10";
  src: url(~@/assets/fonts/Schrift_10.woff) format("woff");
}

@font-face {
  font-family: "Schrift_11";
  src: url(~@/assets/fonts/Schrift_11.woff) format("woff");
}

@font-face {
  font-family: "Schrift_12";
  src: url(~@/assets/fonts/Schrift_12.woff) format("woff");
}

@font-face {
  font-family: "Schrift_13";
  src: url(~@/assets/fonts/Schrift_13.woff) format("woff");
}

@font-face {
  font-family: "Schrift_14";
  src: url(~@/assets/fonts/Schrift_14.woff) format("woff");
}

@font-face {
  font-family: "Schrift_15";
  src: url(~@/assets/fonts/Schrift_15.woff) format("woff");
}

@font-face {
  font-family: "Schrift_16";
  src: url(~@/assets/fonts/Schrift_16.woff) format("woff");
}

.font {
  font-size: 3em;
}

.font-1 {
  font-family: "Schrift_1" !important;
}

.font-2 {
  font-family: "Schrift_2" !important;
}

.font-3 {
  font-family: "Schrift_3" !important;
}

.font-4 {
  font-family: "Schrift_4" !important;
}

.font-5 {
  font-family: "Schrift_5" !important;
}

.font-6 {
  font-family: "Schrift_6" !important;
}

.font-7 {
  font-family: "Schrift_7" !important;
}

.font-8 {
  font-family: "Schrift_8" !important;
}

.font-9 {
  font-family: "Schrift_9" !important;
}

.font-10 {
  font-family: "Schrift_10" !important;
}

.font-11 {
  font-family: "Schrift_11" !important;
}

.font-12 {
  font-family: "Schrift_12" !important;
}

.font-13 {
  font-family: "Schrift_13" !important;
}

.font-14 {
  font-family: "Schrift_14" !important;
}

.font-15 {
  font-family: "Schrift_15" !important;
}

.font-16 {
  font-family: "Schrift_16" !important;
}

.missing {
  position: absolute;
  right: 21px;
  background: red;
  overflow: hidden;
  padding: 8px;
  color: white;
  border-radius: 7px;
  font-weight: bold;
}

.ready {
  position: absolute;
  right: 21px;
  background: #00a803;
  overflow: hidden;
  padding: 8px;
  color: white;
  border-radius: 7px;
  font-weight: bold;
}

.blink {
  animation: blinker 0.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.multiQty {
  background-color: #ff00ff !important;
  /* Magenta Hintergrund */
}

.quantity-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ff00ff;
  color: white;
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 1.8em;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.highlight-qty {
  font-size: 1.8em !important;
  color: #ff00ff !important;
  margin-top: 10px;
}

.highlight-qty .customValue {
  background: #ff00ff !important;
  color: white !important;
  padding: 5px 10px;
  border-radius: 5px;
}

.order-info {
  margin-bottom: 8px;
  line-height: 1.5;
}

.info-item {
  display: inline-block;
  padding: 2px 4px;
  background: #f5f5f5;
  border-radius: 4px;
}

.info-divider {
  margin: 0 8px;
  color: #666;
}

.time-alert {
  margin: 0 !important;
  border-radius: 4px;
}

.time-info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: white;
  font-size: 0.9em;
}

.time-divider {
  color: rgba(255, 255, 255, 0.7);
}

/* Verbesserte Styles für die Produktkarte */
.product-card {
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.product-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.quantity-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background: linear-gradient(135deg, #ff00ff, #ff66ff);
  color: white;
  padding: 8px 16px;
  border-radius: 25px;
  font-size: 1.8em;
  font-weight: bold;
  box-shadow: 0 2px 8px rgba(255, 0, 255, 0.3);
}

.highlight-qty {
  font-size: 1.8em !important;
  color: #ff00ff !important;
  margin-top: 12px;
  transition: all 0.3s ease;
}

.highlight-qty .customValue {
  background: linear-gradient(135deg, #ff00ff, #ff66ff);
  color: white !important;
  padding: 6px 12px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(255, 0, 255, 0.2);
}

/* Verbesserte Buttons */
.action-btn {
  transition: all 0.3s ease;
  text-transform: none;
  letter-spacing: 0.5px;
  font-weight: 600;
}

.action-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.action-btn .v-icon {
  margin-left: 8px;
}

.warning {
  background-color: #FFA726 !important;
  color: white !important;
}

.font-weight-medium {
  font-weight: 500;
}
</style>
